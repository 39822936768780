import React, { useState, useEffect } from 'react';
import './Curtain.css'; // CSS file for the curtain animation

const Curtain = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Trigger the curtain opening after a short delay
    setTimeout(() => setIsOpen(true), 1000); // Adjust delay if needed
  }, []);

  return (
    <div className={`curtain ${isOpen ? 'curtain-open' : ''}`}>
      <div className="curtain-left"></div>
      <div className="curtain-right"></div>

      {/* Inauguration message */}
      {!isOpen && (
        <div className="curtain-message">
          <h1>Welcome to Our Website Inauguration!</h1>
          <p>We're excited to unveil our new platform.</p>
        </div>
      )}
    </div>
  );
};

export default Curtain;
