import React from 'react';
import MembershipInfo from './MembershipInfo';
import './MembershipPage.css';

const MembershipPage = () => {
  return (
    <div className="membership-page">
      <MembershipInfo />
      <section className="membership-join">
        <a href="https://www.ieee.org/join" target="_blank" rel="noopener noreferrer">
          <button className="join-btn">Join IEEE</button>
        </a>
      </section>
    </div>
  );
};

export default MembershipPage;
