import React from 'react';
import './AchievementsAlbum.css';
import Slider from 'react-slick'; // Assuming you're using react-slick for the carousel

// Import your images
import achievement1 from './images/achievement1.jpeg';
import achievement2 from './images/achievement2.jpeg';
import achievement3 from './images/achievement3.jpeg';

const AchievementsAlbum = () => {
  const photos = [
    { src: achievement1, alt: 'Achievement 1' },
    { src: achievement2, alt: 'Achievement 2' },
    { src: achievement3, alt: 'Achievement 3' },
    // Add more images as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
  };

  return (
    <div className="achievements-album">
      <h2 className="album-title">MOMENT OF PRIDE</h2>
      <Slider {...settings}>
        {photos.map((photo, index) => (
          <div key={index} className="album-slide">
            <img src={photo.src} alt={photo.alt} className="album-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AchievementsAlbum;
