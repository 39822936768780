import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section about">
                    <h2>Women Institute of Technology IEEE SB</h2>
                    <p>
                        WIT IEEE SB aims to give an interactive platform for students 
                        to develop professional and technical abilities. Since the start, it is 
                        successfully organizing various events with well-defined subjects.
                    </p>
                    {/* Add social icons here */}
                    <div className="social-icons">
                        <a href="https://www.facebook.com/profile.php?id=100093979242246&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook fa-2x"></i>
                        </a>
                        <a href="https://www.instagram.com/ieee_wit_sb_2016?igsh=MWEzanRxOTFpMXdiYg==" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram fa-2x"></i>
                        </a>
                    </div>
                </div>
                <div className="footer-section recent-posts">
                    <h2>Recent Posts</h2>
                    <ul>
                        <li>Expert talk on IEEE Publication</li>
                        <li>One Day Workshop – On Image Processing & its applications using MATLAB</li>
                        <li>National Unity Day-2023</li>
                        <li>6th IEEE WIT Student Branch Executive Committee Meeting-2023</li>
                        <li>One day Seminar - On strategy to crack Gate & other examinations</li>
                    </ul>
                </div>
                <div className="footer-section contact-info">
                    <h2>Contact Info</h2>
                    <ul>
                        <li><strong>Address:</strong> Chandanwadi, Prem Nagar, Sudhowala, Dehradun (Uttarakhand)</li>
                        <li><strong>Phone:</strong> +91 97204 01816</li>
                        <li><strong>Email:</strong> ieeewitstudent124@gmail.com</li>
                        <li><strong>University Website:</strong> <a href="https://wit.ac.in/" target="_blank" rel="noopener noreferrer">https://wit.ac.in/</a></li>
                    </ul>
                </div>
                {/* Add the map here */}
                <div className="footer-section map">
                    <h2>Our Location</h2>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.3068820673925!2d77.93967027535699!3d30.342227174775058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3908d55a42034335%3A0xd506cec233a25b53!2sWomen%20Institute%20of%20Technology%20(WIT)%2C%20Dehradun!5e0!3m2!1sen!2sin!4v1727774553328!5m2!1sen!2sin"
                        width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2024 WIT DEHRADUN Powered by Drinity Web Solutions | Developers: Akansha Tiwari, Kriti Joshi</p>
            </div>
        </footer>
    );
}

export default Footer;
