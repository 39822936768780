import React from 'react';
import './MembershipInfo.css';

const MembershipInfo = () => {
  return (
    <section className="membership-info">
      <h2>Why Join IEEE?</h2>
      <p>
        As an IEEE member, you gain access to the world's largest technical professional organization. 
        You'll have opportunities to network with industry experts, access cutting-edge research, and participate in IEEE events.
      </p>
      <ul>
        <li>🌐 Access to IEEE Xplore</li>
        <li>💼 Discounts on Conferences & Workshops</li>
        <li>🤝 Networking with Industry Professionals</li>
        <li>🎉 Exclusive IEEE events</li>
        <li>📈 Professional Development</li>
      </ul>
    </section>
  );
};

export default MembershipInfo;
