import React from "react";
import './WhyUsSection.css'; // Make sure to create this CSS file

const WhyUsSection = () => {
  return (
    <div className="why-us-section">
      <h2>WHY WIT IEEE SB?</h2>
      <div className="features">
        <div className="feature">
          <div className="icon">
            <i className="fab fa-android"></i> {/* Use appropriate icon */}
          </div>
          <h3>KEEP TECHNICALLY UP TO DATE</h3>
          <p>
            IEEE is dedicated to advancing technological innovation and
            excellence for the benefit of humanity, connecting more than
            400,000 members to the latest information and the best technical
            resources available. IEEE is a unique way to gauge the benefits of
            IEEE members but at reduced membership rates. Helps you do research,
            get recognition and publish your work to the whole world.
          </p>
        </div>

        <div className="feature">
          <div className="icon">
            <i className="fas fa-globe"></i> {/* Use appropriate icon */}
          </div>
          <h3>JOIN A GLOBAL NETWORK</h3>
          <p>
            When you join IEEE, it serves its members and their interest with 83
            societies, 130 journals, transactions, and magazines; more than 300
            conferences annually and 900 active standards. Apart from it, global
            graphical units, publication, web services, and conferences, IEEE
            remains the world’s largest technical professional association.
          </p>
        </div>

        <div className="feature">
          <div className="icon">
            <i className="fas fa-briefcase"></i> {/* Use appropriate icon */}
          </div>
          <h3>GAIN EXPERTISE AND OPEN CAREER DOORS</h3>
          <p>
            Career door is an email newsletter that has recommended the best
            career advice plus the job of the week from the IEEE job site. It
            provides members with links to news about engineering careers,
            education, & professional issues, and anyone may opt into the
            newsletter. A weekly email containing career advice plus the job of
            the week from the IEEE Job Site.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyUsSection;
