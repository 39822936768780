import React, { useState } from 'react';
import ResourceAccordion from './ResourceAccordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './ResourceLibrary.css';

const resources = [
  { 
    id: 1, 
    category: 'IEEE Papers', 
    title: 'IEEE Xplore: AI Research Paper', 
    description: 'A detailed research paper on AI innovations.', 
    link: 'https://ieeexplore.ieee.org/' 
  },
  { 
    id: 2, 
    category: 'Toolkits', 
    title: 'IEEE Design Toolkit', 
    description: 'A toolkit for IEEE design and development.', 
    link: 'https://www.ieee.org/toolkits' 
  },
  { 
    id: 3, 
    category: 'Conferences', 
    title: 'IEEE Future Conferences', 
    description: 'An overview of upcoming IEEE conferences and events.', 
    link: 'https://www.ieee.org/conferences' 
  },
  { 
    id: 4, 
    category: 'Articles', 
    title: 'IEEE Featured Articles', 
    description: 'An overview of featuring IEEE Articles.', 
    link: 'https://www.ieee.org/conferences' 
  },
  { 
    id: 5, 
    category: 'news', 
    title: 'IEEE News and Updates', 
    description: 'Current News and Updates of IEEE.', 
    link: 'https://www.ieee.org/conferences' 
  }
];

const ResourceLibrary = () => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const filteredResources = resources.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="resource-library">
      <h2>IEEE Resource Library</h2>
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
      <input 
        type="text" 
        placeholder="Search resources..." 
        className="search-bar"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} 
      />
      <div className="resource-list">
        {filteredResources.map(resource => (
          <ResourceAccordion key={resource.id} resource={resource} />
        ))}
      </div>
    </div>
  );
};

export default ResourceLibrary;
