import React, { useEffect, useState } from 'react';
import './Header.css';
import logo from '../components/images/logo.png'; // Importing the logo

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // Dropdown visibility state

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 50);
  };

  useEffect(() => {
    const user = localStorage.getItem('loggedInUser');
    setLoggedIn(!!user);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('loggedInUser');
    setLoggedIn(false);
    window.location.reload();
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 70; // Offset of 70 pixels
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <header className={`header ${scrolled ? 'scrolled' : ''}`}>
      <div className='logo'>
      <a href="https://www.ieee.org/" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="IEEE-WIT-SB Logo" className="logo-image" />
        </a>
      </div>
      <nav className="nav">
        <button onClick={() => scrollToSection('home')}>Home</button>
        <button onClick={() => scrollToSection('about')}>About</button>
        <button onClick={() => scrollToSection('events')}>Events</button>
        <button onClick={() => scrollToSection('contact')}>Founder</button>
        
        {/* Branch Button with Dropdown */}
        <div 
          className="dropdown" 
          onMouseEnter={() => setShowDropdown(true)} 
          onMouseLeave={() => setShowDropdown(false)}
        >
          <button>Branch</button>
          {showDropdown && (
            <div className="dropdown-content">
              <button onClick={() => scrollToSection('branch1')}>IEEE Women Institute of Technology Student Branch</button>
              <button onClick={() => scrollToSection('branch2')}>IEEE WIT Women in Engineering Affinity Group</button>
            </div>
          )}
        </div>

        <button>Contact</button>
      </nav>
    </header>
  );
};

export default Header;
