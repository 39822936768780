import React, { useState, useEffect } from 'react';
import './ImageCarousel.css';
import slide1 from './images/slide1.png';
import slide2 from './images/slide2.png';
import slide3 from './images/slide3.jpeg';
import slide4 from './images/slide4.jpeg';
import slide5 from './images/slide5.jpeg';
import slide6 from './images/slide6.jpeg';
import slide7 from './images/slide7.jpeg';
import slide8 from './images/slide8.jpeg';
import slide9 from './images/slide9.jpeg';
import slide10 from './images/slide10.jpeg';
import slide11 from './images/slide11.jpeg';
import slide12 from './images/slide12.jpeg';
import slide13 from './images/slide13.jpeg';

const images = [slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8, slide9, slide10, slide11, slide12, slide13];

const ImageCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        setFade(true);
      }, 100); // Quick fade-out duration
    }, 5000); // Slide duration

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="image-carousel">
      <div className={`fade-overlay ${fade ? 'fade-in' : 'fade-out'}`}></div>
      <div className="image-container">
        <img src={images[currentSlide]} alt={`Slide ${currentSlide}`} />
      </div>
      <div className="carousel-controls">
        <button onClick={() => {
          setFade(false);
          setTimeout(() => {
            setCurrentSlide((currentSlide - 1 + images.length) % images.length);
            setFade(true);
          }, 100);
        }}>&#10094;</button>
        <button onClick={() => {
          setFade(false);
          setTimeout(() => {
            setCurrentSlide((currentSlide + 1) % images.length);
            setFade(true);
          }, 100);
        }}>&#10095;</button>
      </div>
    </div>
  );
};

export default ImageCarousel;
