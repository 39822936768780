import React, { useState } from 'react';
import jessica from '../components/images/jessica.jpeg';
import bhavika from '../components/images/bhavika.jpeg';
import yashi from '../components/images/yashi.jpeg';
import priyanshik from '../components/images/priyanshik.jpeg';
import amisha from '../components/images/amisha.jpeg';
import priyanshis from '../components/images/priyanshis.jpeg';
import aadya from '../components/images/aadya.jpeg';
import aarchi from '../components/images/aarchi.jpeg';
import aditi from '../components/images/aditi.jpeg';
import aditich from '../components/images/aditich.jpeg';
import akansha from '../components/images/akansha.jpeg';
import ananya from '../components/images/ananya.jpeg';
import astha from '../components/images/astha.jpeg';
import bhumika from '../components/images/bhumika.jpeg';
import disha from '../components/images/disha.jpeg';
import divyanshi from '../components/images/divyanshi.jpeg';
import ipsha from '../components/images/ipsha.jpeg';
import jaishree from '../components/images/jaishree.jpeg';
import kanak from '../components/images/kanak.jpeg';
import kriti from '../components/images/kriti.jpeg';
import kritika from '../components/images/kritika.jpeg';
import manushree from '../components/images/manushree.jpeg';
import monika from '../components/images/monika.jpeg';
import nisha from '../components/images/nisha.jpeg';
import palak from '../components/images/palak.jpeg';
import prabhjot from '../components/images/prabhjot.jpeg';
import priyanshi from '../components/images/priyanshi.jpeg';
import riya from '../components/images/riya.jpeg';
import saumya from '../components/images/saumya.jpeg';
import sneha from '../components/images/sneha.jpeg';
import shaily from '../components/images/shaily.jpeg';
import shalini from '../components/images/shalini.jpeg';
import shivani from '../components/images/shivani.jpeg';
import srishti from '../components/images/srishti.jpeg';
import tanisha from '../components/images/tanisha.jpeg';
import tripti from '../components/images/tripti.jpeg';
import Slider from 'react-slick';
import './TestimonialSlider.css';

const testimonials = [
    {
        key: 'jessica',
        name: 'Jessica Baranwal',
        role: 'Chairman, IEEE Student Branch',
        image: jessica,
    },
    {
        key: 'bhavika',
        name: 'Bhavika Bhatt',
        role: 'WIE Chairman, IEEE Student Branch',
        image: bhavika,
    },
    {
        key: 'yashi',
        name: 'Yashi Dwivedi',
        role: 'Member, IEEE Student Branch',
        image: yashi,
    },
    {
        key: 'priyanshik',
        name: 'Priyanshi Karakoti',
        role: 'Member, IEEE Student Branch',
        image: priyanshik,
    },
    {
        key: 'amisha',
        name: 'Amisha Rawat',
        role: 'Member, IEEE Student Branch',
        image: amisha,
    },
    {
        key: 'priyanshis',
        name: 'Priyanshi Sharma',
        role: 'Member, IEEE Student Branch',
        image: priyanshis,
    },
    {
        key: 'shalini',
        name: 'Shalini Rawat',
        role: 'Member, IEEE Student Branch',
        image: shalini,
    },
    {
        key: 'astha',
        name: 'Astha Bijlwan',
        role: 'Member, IEEE Student Branch',
        image: astha,
    },
    {
        key: 'jaishree',
        name: 'Jaishree Yadav',
        role: 'Member, IEEE Student Branch',
        image: jaishree,
    },
    {
        key: 'akansha',
        name: 'Akansha Tiwari',
        role: 'Member and Website Developer, IEEE Student Branch',
        image: akansha,
    },
    {
        key: 'kriti',
        name: 'Kriti Joshi',
        role: 'Member and Website Developer, IEEE Student Branch',
        image: kriti,
    },
    {
        key: 'saumya',
        name: 'Saumya Joshi',
        role: 'Member, IEEE Student Branch',
        image: saumya,
    },
    {
        key: 'palak',
        name: 'Palak Binjola',
        role: 'Member, IEEE Student Branch',
        image: palak,
    },
    {
        key: 'shivani',
        name: 'Shivani Dhoni',
        role: 'Member, IEEE Student Branch',
        image: shivani,
    },
    {
        key: 'aditich',
        name: 'Aditi Chand',
        role: 'Member, IEEE Student Branch',
        image: aditich,
    },
    {
        key: 'ananya',
        name: 'Ananya Dixit',
        role: 'Member, IEEE Student Branch',
        image: ananya,
    },
    {
        key: 'bhumika',
        name: 'Bhumika Budhlakoti',
        role: 'Member, IEEE Student Branch',
        image: bhumika,
    },
    {
        key: 'divyanshi',
        name: 'Divyanshi',
        role: 'Member, IEEE Student Branch',
        image: divyanshi,
    },
    {
        key: 'ipsha',
        name: 'Ipsha Singh',
        role: 'Member, IEEE Student Branch',
        image: ipsha,
    },
    {
        key: 'kritika',
        name: 'Kritika Pandey',
        role: 'Member, IEEE Student Branch',
        image: kritika,
    },
    {
        key: 'monika',
        name: 'Monika Mehta',
        role: 'Member, IEEE Student Branch',
        image: monika,
    },
    {
        key: 'prabhjot',
        name: 'Prabhjot kaur',
        role: 'Member, IEEE Student Branch',
        image: prabhjot,
    },
    {
        key: 'priyanshi',
        name: 'Priyanshi Joshi',
        role: 'Member, IEEE Student Branch',
        image: priyanshi,
    },
    {
        key: 'riya',
        name: 'Riya',
        role: 'Member, IEEE Student Branch',
        image: riya,
    },
    {
        key: 'sneha',
        name: 'Sneha Dhami',
        role: 'Member, IEEE Student Branch',
        image: sneha,
    },
    {
        key: 'srishti',
        name: 'Srishti',
        role: 'Member, IEEE Student Branch',
        image: srishti,
    },
    {
        key: 'tripti',
        name: 'Tripti Gupta',
        role: 'Member, IEEE Student Branch',
        image: tripti,
    },
    {
        key: 'aadya',
        name: 'Aadya Gupta',
        role: 'Member, IEEE Student Branch',
        image: aadya,
    },
    {
        key: 'aditi',
        name: 'Aditi Sharma',
        role: 'Member, IEEE Student Branch',
        image: aditi,
    },
    {
        key: 'aarchi',
        name: 'Aarchi Agarwal',
        role: 'Member, IEEE Student Branch',
        image: aarchi,
    },
    {
        key: 'disha',
        name: 'Disha',
        role: 'Member, IEEE Student Branch',
        image: disha,
    },
    {
        key: 'kanak',
        name: 'Kanak Upreti',
        role: 'Member, IEEE Student Branch',
        image: kanak,
    },
    {
        key: 'manushree',
        name: 'Manushree Tonk',
        role: 'Member, IEEE Student Branch',
        image: manushree,
    },
    {
        key: 'nisha',
        name: 'Nisha Bisht',
        role: 'Member, IEEE Student Branch',
        image: nisha,
    },
    {
        key: 'shaily',
        name: 'Shaily Bisht',
        role: 'Member, IEEE Student Branch',
        image: shaily,
    },
    {
        key: 'tanisha',
        name: 'Tanisha Pant',
        role: 'Member, IEEE Student Branch',
        image: tanisha,
    }
    
];

const Testimonial = () => {
    const [selectedTestimonial, setSelectedTestimonial] = useState(testimonials[0]);
  
    const handleAvatarClick = (testimonial) => {
      setSelectedTestimonial(testimonial);
    };
  
  
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      nextArrow: <div className="slick-next">&rarr;</div>,
      prevArrow: <div className="slick-prev">&larr;</div>,
      focusOnSelect: true,
      centerMode: true,
    };
  
    return (
      <div className="testimonial-container">
        <h2 className="testimonial-title">IEEE WIT-SB</h2>
        <p className="testimonial-subtitle">This is our current members of our student branch.</p>
        <Slider {...settings} className="testimonial-avatars">
          {testimonials.map((testimonial) => (
            <img
              key={testimonial.key}
              src={testimonial.image}
              alt={testimonial.name}
              className={`testimonial-avatar ${selectedTestimonial.key === testimonial.key ? 'highlighted-avatar' : ''}`}
              onClick={() => handleAvatarClick(testimonial)}
            />
          ))}
        </Slider>
        <div className="testimonial-details">
          <h3 className="testimonial-name">{selectedTestimonial.name}</h3>
          <p className="testimonial-role">{selectedTestimonial.role}</p>
        </div>
        <button className="past-members-btn">PAST MEMBERS</button>
      </div>
    );
  }
  
  export default Testimonial;