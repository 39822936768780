import React from 'react';
import './TopNav.css';
import vmsb from '../components/images/vmsb.jpeg';

const TopNav = () => {
  return (
    <div className="topnav">
      <div className="logo-container">
      <a href="https://www.uktech.ac.in" target="_blank" rel="noopener noreferrer">
        <img src={vmsb}  alt="Left Logo" className="topnav-left-logo" />
        </a>
      </div>
      <div className="topnav-text">
        <h1>VEER MADHO SINGH BHANDARI UTTARAKHAND TECHNICAL UNIVERSITY, DEHRADUN, INDIA</h1>
        <h2>महिला प्रौद्योगिकी संस्थान, देहरादून</h2>
        <h3>WOMEN INSTITUTE OF TECHNOLOGY, DEHRADUN, UTTARAKHAND</h3>
        <h4>IEEE-WIT-STUDENT-BRANCH</h4>
        <a href="https://www.ieee.org/join" target="_blank" rel="noopener noreferrer" className="carousel-button">Enroll Now</a>
      </div>
      <div className="logo-container">
      <a href="https://wit.ac.in" target="_blank" rel="noopener noreferrer">
        <img src="https://wit.ac.in/wp-content/uploads/WhatsApp_Image_2023-08-20_at_8.04.50_PM-removebg-preview.png" alt="Right Logo" className="topnav-right-logo" />
        </a>
      </div>
    </div>
  );
};

export default TopNav;
