import './App.css';
import ImageCarousel from './components/ImageCarousel';
import Header from './components/Header';
import WhyUsSection from './components/WhyUsSection';
import AchievementsAlbum from './components/AchievementsAlbum';
import EventsSection from './components/EventsSection';
import FounderMessage from './components/FounderMessage';
import Footer from './components/Footer';
import TestimonialSlider from './components/TestimonialSlider';
import MembershipPage from './components/MembershipPage';
import ResourceLibrary from './components/ResourceLibrary';
import IEEEBranchRoles from './components/IEEEBranchRoles'; 
import TopNav from './components/TopNav'; 
import IntroSection from './components/IntroSection';
import Curtain from './components/Curtain';

function App() {
    return (
      <div className="App">
        <div> <Curtain/> </div>
                <div> <TopNav/> </div>
                <div id="home"> <ImageCarousel/> </div>
                <div> <Header/> </div>
                <div> <MembershipPage/></div>
                <div> <IntroSection/></div>
                <div id="about"> <WhyUsSection/></div>
                <div> <AchievementsAlbum/></div>
                <div id="events"> <EventsSection/></div>
                <div> <TestimonialSlider/></div>
                <div> <IEEEBranchRoles/></div>
                <div id="contact"> <FounderMessage/></div>
                <div> <ResourceLibrary/></div>
                <div> <Footer/></div>
                </div>
  );
}

export default App;
