import React, { useState } from 'react';
import './ResourceAccordion.css';

const ResourceAccordion = ({ resource }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="resource-accordion">
      <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
        <h3>{resource.title}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <p>{resource.description}</p>
          <a href={resource.link} target="_blank" rel="noopener noreferrer">
            Visit Resource
          </a>
        </div>
      )}
    </div>
  );
};

export default ResourceAccordion;
