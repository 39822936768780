import React from 'react';
import './IntroSection.css'; // Ensure this CSS file is created

const IntroSection = () => {
  return (
    <div className="intro-section">
        <h1>Veer Madho Singh Bhandari Uttarakhand Technical University,</h1>
      <h2>Women Institute of Technology</h2>
      <p>
        At the Women Institute of Technology, affiliated with Uttarakhand Technical University,<br /> we are dedicated to empowering women through education, innovation, and technology.
      </p>

      {/* Main container for images and text */}
      <div className="features-container">
        {/* First Feature: Image on the left, text on the right */}
        <div className="feature-row">
          <img src="https://uktech.ac.in/downloadmedia/siteContent/202312031723282581VC_SIR_websiteNov2023_PHOTO3.jpg" alt="First Feature" className="feature-image" />
          <div className="feature">
            <h3>Innovation at UTU</h3>
            <p>
              UTU is a hub of technological advancement, providing students with cutting-edge facilities and a vibrant learning environment that fosters creativity and innovation.
            </p>
          </div>
        </div>

        {/* Second Feature: Image on the left, text on the right */}
        <div className="feature-row">
          <img src="https://wit.ac.in/wp-content/uploads/IMG_7946-1-1536x1024.jpg" alt="Second Feature" className="feature-image" />
          <div className="feature">
            <h3>Empowerment at WIT</h3>
            <p>
              WIT focuses on nurturing young women leaders in technology, offering a supportive community, mentorship, and a plethora of opportunities for growth and development.
            </p>
          </div>
        </div>
      </div>

      <p className="call-to-action">
        Join us in shaping the future of technology and empowering women to achieve their dreams!
      </p>
    </div>
  );
};

export default IntroSection;
