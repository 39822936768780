import React from 'react';
import founder from '../components/images/founder.jpeg'; 

const FounderMessage = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>A MESSAGE FROM OUR FOUNDER</h2>
      <div style={styles.content}>
        <img
          src={founder}
          alt="Founder"
          style={styles.image}
        />
        <div style={styles.textContainer}>
          <p style={styles.message}>
            "My message to everyone considering joining the Women Institute of Technology IEEE Student Branch is that here you will experience 360° Development: 180° Comprehensive Development and 180° Personal Inner Development. You will stay up-to-date with various new inventions and research emerging worldwide by building a strong network with other students and experts. You will have excellent opportunities for your career by attending various seminars, conferences, activities, etc. You can build your confidence and foster all-around development."
          </p>
          <p style={styles.signature}>
            <strong>Krishan Chandra Mishra,</strong> Senior Member IEEE, USA (93922752) <br />
            Former Joint Secretary, IEEE UP Section, India (2021-23) <br />
            Former Convener, Young Professional Affinity Group, IEEE UP Section, India (2018,2019,2020) <br />
            Counselor, IEEE WIT Student Branch, Dehradun, Uttarakhand, India (2016 to present) <br />
            Conference Secretary: IEEE WIECON ECE-2017 / 2019-WITCON ECE <br />
            Head of Department (I/C), Electrical Engineering Department & Assistant Professor in E.C.E. Department, 
            Women Institute of Technology (State Govt. Institute, Affiliated to A.I.C.T.E.) <br />
            Veer Madho Singh Bhandari Uttarakhand Technical University Campus Institute, Suddhowala.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '50px',
    textAlign: 'center',
    backgroundColor: '#f9f9f9', 
    borderRadius: '10px', 
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
  },
  heading: {
    fontSize: '2.5em',
    marginBottom: '20px',
    position: 'relative',
    display: 'inline-block',
    paddingBottom: '10px',
    borderBottom: '2px solid #ff6347',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start', 
    justifyContent: 'center',
    marginTop: '30px',
    flexWrap: 'wrap', 
  },
  image: {
    width: '32.5%',
    height: '425px',
    marginRight: '30px',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', 
  },
  textContainer: {
    textAlign: 'left',
    maxWidth: '600px',
  },
  message: {
    fontSize: '1.1em',
    color: '#555',
    marginBottom: '20px',
    lineHeight: '1.6', 
  },
  signature: {
    fontSize: '0.9em',
    color: '#777',
    lineHeight: '1.5', 
  },
};

export default FounderMessage;
