// EventsSection.jsx
import React from 'react';
import './EventsSection.css';
import image1 from '../components/images/image1.jpg'; 
import image2 from '../components/images/image2.jpg';
import image3 from '../components/images/image3.jpg'; 
import image4 from '../components/images/image4.jpg';
import image5 from '../components/images/image5.jpg';
import image6 from '../components/images/image6.jpg';
import image7 from '../components/images/image7.jpg'; 
import image8 from '../components/images/image8.jpg'; 

const EventsSection = () => {
  const events = [
    {
      image: image1 ,
      title: 'Expert talk on IEEE Publication ',
      description: 'Technical Writing and Enhancing Your Research by Ranbir S Sedhey ',
    },
    {
      image: 'https://phdtopic.com/wp-content/uploads/2021/06/Image-Processing-Thesis-Using-Matlab.png',
      title: 'One Day Workshop ',
      description: 'Workshop on Image Processing & its applications using MATLAB by Dr. Rajiv Verma from IIIT Sonipath',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE5KMuxSQQ1xbn-0MvSqIxvWVut7yRjhWmgQ&s',
      title: 'One Day Workshop',
      description: ' Workshop on MATLAB by Dr.Mohan IIIT Sonipath  ',
    },
    {
      image: image2,
      title: 'National Unity Day-2023 ',
    },
    {
      image: image3,
      title: '6th IEEE WIT Student Branch Executive Committee Meeting-2023',
    },
    {
      image: image4,
      title: 'One day Seminar',
      description: 'Seminar on strategy to crake Gate & other examination by Mr. Shishir Persal',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380388',
      title: 'Knowledge Sharing Session',
      description: ' Session on IEEE by K.C.Mishra Joint Secretary IEEE UP Section on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381244',
      title: 'Smart India Hackathon',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380385',
      title: 'The Skit with Social Message',
      description: ' Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSptg66NZl85ez0ebWYsPRXFRODLLabxUXDhg&s',
      title: 'Searching the clue Competition ',
      description: ' Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380379',
      title: 'Spoon Race Competition',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNk-YEJL9Bixzc8Dq61Du3j77XCqxumgvMtQ&s',
      title: 'Spell Bee Competition ',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380372',
      title: 'Volleyball Competition ',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://web-japan.org/kidsweb/archives/life/action/06-02/image/act060201.jpg',
      title: 'Two Legged Race Competition',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381238',
      title: 'Soft skills Development Workshop Program',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380058',
      title: 'IEEE Membership Development Program 2024 ',
      description: 'On the Occasion of IEEE Day 2023 ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380246',
      title: 'Pictionary Competition',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380245',
      title: 'Ludo Competition',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380244',
      title: 'Chess Competition',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL50jnB2qdvgR-twnFFa_1n_7dVdoyIyuazw&s',
      title: 'Yoga Competition', 
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSETqFuIhYMb69-wTfyQ-YC44BikwQ2_A2UjQ&s',
      title: 'Technical Quiz Competition',
      description: ' Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380238',
      title: 'Technical Presentation',
      description: 'On the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380237',
      title: 'Live Sketching Competition',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380236',
      title: 'Origami Competition',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380235',
      title: 'Poster Making Competition ',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: image5,
      title: 'Expert talk',
      description: 'By Professor Manoj Kumar Panda, Director WIT, Dehradun on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380067',
      title: 'Dance competition',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380075',
      title: 'Model artist Competition ',
      description: 'Competition on the Occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380065',
      title: 'Singing competition ',
      description: 'Competition on the occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380061',
      title: 'Jamming session',
      description: 'On the occasion of IEEE Day 2023',
    },{
      image: 'https://events.vtools.ieee.org/event/picture/380054s',
      title: 'Award distribution Ceremony',
      description: 'On the occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380052',
      title: 'High Tea ',
      description: 'On the occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380074',
      title: 'Model Competition',
      description: 'On the occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380077',
      title: 'Student Branch T-shirt launching',
      description: 'On the occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380079',
      title: 'Expert talk ',
      description: ' By Professor K.K.S.Mer, Director BTKIT, Dwarahat',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEYAZlXU0BRXFe4ViLk50TMzru8HIz8xavPg&s',
      title: 'Cake cutting ceremony',
      description: 'On the occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380078',
      title: 'Lamp of Lighting ',
      description: 'On the occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380076',
      title: 'IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/379939',
      title: 'Inaugural Ceremony of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380082',
      title: 'Sarasvati Vandana ',
      description: 'On the occasion of IEEE Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381234',
      title: 'Lalbahadur Shastri Jayanti Celebration 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381232',
      title: 'Gandhi Jayanti Celebration 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381228',
      title: 'Swachata Pakhwada',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381227',
      title: 'Wall Painting Competition',
    },
    {
      image: image3,
      title: '5th IEEE WIT Student Branch Executive Committee Meeting-2023',
    },{
      image: 'https://events.vtools.ieee.org/event/picture/381258',
      title: 'Vishwakarma Puja Celebration 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381546',
      title: 'Bridge It',
      description: 'Bridge making competition',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381545',
      title: 'Tech Out of Waste ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381542',
      title: 'Tech Presentation ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381257',
      title: 'Flash Mob Event',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381539',
      title: 'Algo Meltdown',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381538',
      title: 'Mathdoku Hunt',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEizQU_SWb5RUa_mFzQ7GTcrPe0JukQCBufg&s',
      title: 'Engineers Day 2023 Celebration',
      description: 'Expert Talk by Numan Mehraj on Data Science',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381214',
      title: 'Teachers Day Celebration 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381222',
      title: 'Coloured cow seminar',
      description: 'By Ms arushi Dubey & Ms Mehak Jain',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381210',
      title: 'Art Day out competition',
    },
    {
      image: image6,
      title: 'Session-5',
      description: 'By Dr. Sanjay (HEMP) (Yoga + Meditation)',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Ms. Jyotika Bedi, Counseling Psychologist',
    },
    {
      image: image6,
      title: 'Session-4 ',
      description: 'By Dr. Sanjay (HEMP) (Yoga + Meditation)',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3QLe_UrzqXN0QO2A0SoHkzgNBldS0rxvCkQ&s',
      title: 'Chandrayan Poster competition',
    },{
      image: image1,
      title: 'Expert talk ',
      description: 'By Ms. Shweta , Head, Affinity, Personality Groomer',
    },
    {
      image: image6,
      title: 'Session-3',
      description: 'By Dr. Sanjay (HEMP) (Yoga + Meditation)',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Dr. B.S. Sanjay (Padamshree)',
    },
    {
      image: image6,
      title: 'Session-2 ',
      description: 'By Dr. Sanjay (HEMP) (Yoga + Meditation)',
    },
    {
      image: image6,
      title: 'Session-1',
      description: 'By Dr. Sanjay (HEMP) (Yoga + Meditation)',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Dr. Urmila Pande, Expert in Yoga & Cosmic Healing',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Mr. Akhil Pratap Singh, Treasurer, UTU Alumni Association',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'Introduction: The Oracle Cloud Day – Ahmedabad event ...',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Dr. Shishir Prasad, Panel Expert & Author for AYUSH',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Dr. Raina, Director, Corporate Relations from ICFAI Business School',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Dr. Rajat Aggarwal, IIT Roorkee',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Colonel Reema Sobti, Colonel Instructor air Indian Military Academy',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Prof. Durgesh Pant, Director General, UCOST, Uttarakhand',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: ' By Prof. V. K. Tewari, Founding VC of Uttarakhand Technical University, Dehradun',
    },
    {
      image: image3,
      title: '4th IEEE WIT Student Branch Executive Committee Meeting-2023',
    },{
      image: image7,
      title: 'Independence Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/380371',
      title: 'Webinar on NAVIGATING CARRER PATHS AND EXPLORING RESEARCH FRONTIERS',
      description: 'By Mansi (PhD scholar, NIT Rourkela)',
    },
    {
      image: image3,
      title: '3rd IEEE WIT Student Branch Executive Committee Meeting-2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381252',
      title: 'Technovation',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8gASP4uI34ZjZH6CBFgfBowAXLloTYg5jtQ&s',
      title: 'Awareness Session',
      description: 'By Mr.Brigu Dass, Assam ',
    },
    {
      image: image3,
      title: '2nd IEEE WIT Student Branch Executive Committee Meeting-2023',
    },
    {
      image: image8,
      title: 'Get-to-gather for all members  ',
    },
    {
      image: 'https://cdn.prod.website-files.com/65d8ad2ed4188109392b808a/65d8ad2ed4188109392b83bb_1584607782458.jpeg',
      title: 'Knowledge sharing session',
      description: 'By Prof. Sadanand Damodar Sapry(Retired), NIT Bhopal',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuRvZb0canAa2ZWjrRZ03aFkaukyAwOlBjNg&s',
      title: 'Women Day Celebration 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381254',
      title: '"Splash"',
      description: 'Holi Celebration 2023',
    },
    {
      image: image3,
      title: '1st IEEE WIT Student Branch Executive Committee Meeting-2023',
    },
    {
      image: 'https://images.hindustantimes.com/rf/image_size_630x354/HT/p2/2020/01/23/Pictures/rd-parade-s-full-dress-rehearsal_53613cfa-3dd1-11ea-ae56-f909945546d5.jpg',
      title: 'Republic Day 2023',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/381203',
      title: 'Celebration of Uttarakhand Foundation Day 2022',
    },
    {
      image: 'https://png.pngtree.com/png-vector/20220612/ourmid/pngtree-quiz-show-flat-color-vector-illustration-competition-presenter-vector-vector-png-image_31434098.jpg',
      title: 'Quiz Competition',
      description: 'On the Occasion of the Sardar Vallabhbhai Patel Jayanti Celebration-2022',
    },
    {
      image: 'https://www.iasexam.com/wp-content/uploads/5-67-780x470.webp',
      title: 'Sardar Vallabhbhai Patel Jayanti Celebration-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/330489',
      title: '"Run for Unity"',
      description: 'On the occasion of Sardar Vallabhbhai Patel Jayanti ',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Dr. D. P. Kothari Ex-Director IIT Delhi',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/330499',
      title: 'Rangoli Competition',
      description: 'On the Occasion of DIWALI Celebration-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/330496',
      title: ' "CHIRAG"',
      description: 'On the occasion of the DIWALI FEST-2022',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Mr. Mohit Kumar from QUALCOMM',
    },
    {
      image: image1,
      title: 'Expert talk',
      description: 'By Mrs. Shrestha Singh from QUALCOMM',
    },
    {
      image: image3,
      title: '4th Executive Committee meeting',
      description: ' IEEE WIT Student Branch & IEEE WIE-WIT affinity group-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329428',
      title: 'Natural Photography Competition',
      description: 'On the Occasion of #IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329424',
      title: 'Slogan Writing Competition',
      description: 'On the Occasion of #IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329426',
      title: 'Debate Competition on topic "DESH KI SEEMAYE"',
      description: 'On the Occasion of #IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329423',
      title: 'Dr.R.P.S.Gangwar Director, WIT welcome Speech',
      description: 'On the Occasion of #IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329421',
      title: 'Cake cutting ceremony',
      description: 'On the Occasion of #IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329414',
      title: 'Chart designing Competition on topic "Country borders"',
      description: 'On the Occasion of #IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329408',
      title: 'Poster Making Competition',
      description: 'On the Occasion of #IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329406',
      title: 'Essay Competition',
      description: 'On the Occasion of #IEEE Day 2022',
    },{
      image: 'https://events.vtools.ieee.org/event/picture/329411',
      title: 'Celebrate IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329403',
      title: 'Rangoli Competition',
      description: 'On the Occasion of #IEEE Day 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329825',
      title: 'Shri Lal Bahadur Shastri Jayanti Celebration 2022',
    },
    {
      image: 'https://iasgyan.in/ig-uploads/images/GANDHI%E2%80%99S_SATYAGRAHA_IN_SOUTH_AFRICA.jpg',
      title: 'Gandhi Jayanti Celebration 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/330502',
      title: 'Vishwakarma Pooja Celebration-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/330516',
      title: 'Chess Competition',
      description: 'On the Occasion of Engineers Day-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/330514',
      title: 'Essay Writing Competition',
      description: 'On the Occasion of Engineers Day-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/330518',
      title: 'Ludo Competition',
      description: 'On the Occasion of Engineers Day-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/330510',
      title: 'Engineers Day Celebration 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329401',
      title: 'Azadi ka Amrit Mahotsav',
    },
    {
      image: image3,
      title: '3rd Executive Committee meeting ',
      description: 'IEEE WIT Student Branch & IEEE WIE-WIT affinity group-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329871',
      title: 'Welcome to UTU Vice Chancellor Prof. Onkar Singh',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329867',
      title: 'Plantation',
      description: 'On the occasion of Harela Festival 2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329980',
      title: 'Lecture on Communication skills development',
      description: 'By Ms. Konika Mukhrjee  ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329832',
      title: 'International Yoga Day Celebration 2022 ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329869',
      title: ' International Environment Day Celebration 2022',
      description: 'Plantation',
    },
    {
      image: image3,
      title: '2nd Executive Committee meeting ',
      description: 'IEEE WIT Student Branch & IEEE WIE-WIT affinity group-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329993',
      title: 'Plantation',
      description: 'On the occasion of Dr. B. R. Ambedkar Jayanti',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329830',
      title: 'APT-2022 ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329828',
      title: 'Faculty High Tea & IEEE Awareness Program ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/307529',
      title: 'Rangoli competition ',
      description: 'On International Womens Day 2022 organized by IEEE WIT Student Branch',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/307528',
      title: ' Poster Making Competition',
      description: 'On International Womens Day 2022 organized by IEEE WIT Student Branch',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/307525',
      title: 'Honor to Institute Womens',
      description: 'On International Womens Day 2022 organized by IEEE WIT Student Branch',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/307524',
      title: 'Common meeting and High Tea',
      description: 'On International Womens Day 2022 organized by IEEE WIT Student Branch',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/307520',
      title: 'volleyball competition',
      description: 'On International Womens Day 2022 organized by IEEE WIT Student Branch',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/307523',
      title: 'Chess competition',
      description: 'On International Womens Day 2022 organized by IEEE WIT Student Branch',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/307522',
      title: 'Table Tennis competition',
      description: 'On International Womens Day 2022 organized by IEEE WIT Student Branch',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/307531',
      title: 'Distinguish Lecture by Dr. R P S Gangwar',
      description: 'On International Womens Day 2022 organized by IEEE WIT Student Branch',
    },
    {
      image: image3,
      title: '1st Executive Committee meeting',
      description: 'IEEE WIT Student Branch & IEEE WIE-WIT affinity group-2022',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329826',
      title: 'Sarasvati Pooja Celebration 2022',
    },{
      image: 'https://events.vtools.ieee.org/event/picture/329990',
      title: 'Lecture on Basic Knowledge sharing session of Human Values',
      description: 'By Dr. Lohit Jain',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329988',
      title: 'Inter Branch Poster Competition',
      description: 'On topic "Development in India last 20 year"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329992',
      title: 'Session on DXC Technology by ICT Academy ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329986',
      title: 'Poster Making Competition',
      description: 'On topic "Know about Uttarakhand"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329983',
      title: 'Basic knowledge sharing session of Yoga',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/329982',
      title: 'Lecture on Basic Knowledge sharing session of Mechanical Engineering',
      description: 'By Dr. Navrattan Kaushik',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/284919',
      title: 'IEEE DAY 2021',
      description: 'Plantation',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/284929',
      title: 'Lal Bahadur Sastri and Gandhi Jayanti Celebration ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/284921',
      title: 'Gandhi Jayanti',
      description: 'Clothes distribution program for cleaning employee',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/284926',
      title: 'Col. Rautela an Awareness Lecture',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/284925',
      title: 'Celebration of the Vishwakarma Puja',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/284933',
      title: 'Uttarakhand Lok-Parv Harela Festival celebration',
      description: 'Plantation',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/287055',
      title: 'Two day Webinar',
      description: 'On "Building a Start-Up: A Guide"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/287054',
      title: 'Teachers Day Celebration 2021',
    },
    {
      image: image7,
      title: 'Independence Day Celebration 2021',
    },{
      image: image3,
      title: '2nd Executive Committee Meeting',
      description: 'IEEE WIT Student Branch dated 26-06-2021',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/286266',
      title: 'International Yoga Day 2021',
    },
    {
      image: image3,
      title: '1st Executive Committee Meeting ',
      description: ' IEEE WIT Student Branch dated 25-03-2021',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/265178',
      title: 'New Education Policy',
      description: 'By Dr.J.Ramkumar, Professor, IIT, Kanpur',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/265220',
      title: 'New Education Policy-2020',
      description: 'By Dr.Manoj Kumar Shukla, Director, REC, Kannauj',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/265106',
      title: 'National Education Policy',
      description: 'By Dr.P.Nagabhushan Director IIIT Priyagraj',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/286269',
      title: 'Lecture on Knowledge Enhance About IEEE',
      description: 'By Dr. Satish Kumar, Vice President, IEEE UP Section',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245910',
      title: 'Swachh Bharat Abhiyan',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245914',
      title: 'Celebration of Gandhi Jayanti 2020',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246226',
      title: 'Webinar',
      description: ' On "Additive manufacturing"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246348',
      title: '03 day hands-on training',
      description: 'On "Application of MATLAB"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246390',
      title: 'Three days online Hands-on training',
      description: 'On "Internet of Things"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246405',
      title: 'Three days of online Hands-on training',
      description: 'On "Robotics"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246221',
      title: 'Webinar',
      description: 'On A brief Introduction and Importance of Sustainable Energy Storage/Conversion Devices',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246212',
      title: 'Webinar ',
      description: 'On "An Overview of Citizen Centric E-Services"',
    },{
      image: 'https://events.vtools.ieee.org/event/picture/246398',
      title: 'Three days online Hands-on training',
      description: 'On "Revit Modeling and Architecture"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246395',
      title: 'Three days online Hands-on training',
      description: 'On "QGIS"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246076',
      title: '"Building a Start-Up: A Guide"',
      description: 'By Mr. Aqib  Co-Founder (Azah)',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246095',
      title: '"Building a Start-Up: A Guide"',
      description: 'By Mr. Siddharth Sharma, Co-Founder & Director (Heal-Agnostic Innovations Pvt. Ltd.)',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246177',
      title: 'Webinar',
      description: 'On "Seismic Bearing Capacity of Shallow Foundation"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246090',
      title: '"Building a Start-Up: A Guide"',
      description: 'By Mr. Abhishek Sharma  Design Lead (ColoredCow)',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246180',
      title: 'Webinar',
      description: 'On "Earthquake Engineering: Role or site effect"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245993',
      title: 'Teachers Day Celebration 2020',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246235',
      title: 'Webinar',
      description: 'On "COVID-19: Impact and Future Prospects in Industry and Academia"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246173',
      title: 'Webinar',
      description: 'On "Application Potential of Coir Geotextiles in Unpaved Roads"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246169',
      title: 'Webinar',
      description: 'on "Seismic Hazard Assessment (SHA): Procedures"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245883',
      title: 'Webinar ',
      description: 'On "Advancement in communication Network" by Dr.G.S.Tomar',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246162',
      title: 'Webinar',
      description: 'On Advances in Ground Improvement Techniques',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245876',
      title: 'Webinar',
      description: 'On "How to prepare the technical presentation" by Dr.G.S.Tomar',
    },{
      image: 'https://events.vtools.ieee.org/event/picture/245515',
      title: 'Lecture',
      description: 'On "Design Thinking" by Dr. Kripa Shankar',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245513',
      title: 'Lecture',
      description: 'On "Digital Divide in India during COVID-19" by Dr.Upasana Gitanjali Singh',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245432',
      title: 'Lecture',
      description: 'On "Importance of Interdisciplinary Education and Research" by Dr.G.S.Tomar',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245395',
      title: 'Lecture',
      description: 'On "Career planning after B.Tech" by Mr. Jaiprakash Chauhan',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245391',
      title: 'Lecture',
      description: 'On "Artificial Intelligence" by Prof. Kripa Shankar',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245388',
      title: 'Lecture',
      description: 'On "Machine learning and Deep learning applications" by Dr. Parma Nand',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245381',
      title: 'Lecture',
      description: 'On "Advance Semiconductor device for low power application" by Dr. Balraj Singh',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245380',
      title: 'Lecture',
      description: 'On "Time Management" by Prof. S. K. Thakur',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245376',
      title: 'Lecture',
      description: 'On "Cascade and Cascode MOS Amplifiers" by Dr. Tajinder Singh Arora',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245375',
      title: 'Lecture',
      description: 'On "Image Denoising" by Dr. Rajiv Verma',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245371',
      title: 'Lecture',
      description: 'On "MIMO Antennas for 5G Application" by Prof. Kumar Vaibhav Srivastava',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245366',
      title: 'Lecture',
      description: 'On "Organic Light-Emitting Diode" by Dr. Sanjeev Naithani',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245362',
      title: 'Lecture',
      description: 'On "Monitoring and Control via cloud in an IoT framework" by Dr. Gaurav Verma',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245358',
      title: 'Lecture',
      description: 'On "Role of Linux in High Performance Computing Environment" by Dr. Sunil Kumar Singh',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245343',
      title: 'Lecture',
      description: 'On "Digital Manufacturing" by Prof. J.Ramkumar',
    },{
      image: 'https://events.vtools.ieee.org/event/picture/245342',
      title: 'Lecture',
      description: 'On "Design Thinking for COVID Product Mitigation" by Prof. J.Ramkumar',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245337',
      title: 'Lecture',
      description: 'On "How to avoid Plagiarism and Publication Ethics for IEEE Authors" by Dr. Dilip Kumar Sharma',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245331',
      title: 'Lecture',
      description: 'On "Education, Technology, Interdisciplinary Research & Innovation" by Prof. Narendra S. Chaudhari',
    },
    {
      image: image7,
      title: 'Independence Day 2020',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246202',
      title: 'International Webinar',
      description: 'On Innovations in Scientific Research- 2020',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245920',
      title: 'World Environment Day 2020',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245900',
      title: 'IEEE WIT Student Branch Meeting',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246125',
      title: 'Industrial Visit',
      description: 'In Neotek Quartz Surfaces, Udaipur',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246259',
      title: 'Hands-on training',
      description: 'On "Advance Application of Microcontroller"',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245992',
      title: '03 Day Hands-on Training',
      description: 'On "E-Tab" ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246101',
      title: 'ECE Department Industrial Visit',
      description: 'At OMEGA ELECTRONICS, JAIPUR',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/246108',
      title: 'Industrial Visit',
      description: 'In Tesca Technologies Private Limited, Jaipur',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/245994',
      title: 'Dr. Ahmed Iqbal',
      description: 'IAS Interaction with WIT Student',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218432',
      title: '2019-WITCON ECE',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218792',
      title: 'Lecture',
      description: 'On Surgical Strike ',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218678',
      title: 'Swach Bharat Abhiya',
      description: 'On the Occassion of Gandhi Jayanti',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218669',
      title: 'IEEE DAY Celebration',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218438',
      title: 'Workshop / Hands on Training',
      description: 'On Basic Application of Microprocessor and Microcontroller',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218437',
      title: 'Workshop / Hands on Training',
      description: 'On Basic C Language',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218658',
      title: 'Engineers Day Celebration',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218885',
      title: 'Workshop / Hands on Training',
      description: 'on industrial automation using PLC',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218787',
      title: 'Workshop / Hands on Training',
      description: 'On Basic ARDUINO',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/218435',
      title: 'Workshop / Hands on Training',
      description: 'On Advance JAVA',
    },
    {
      image: 'https://events.vtools.ieee.org/event/picture/197220',
      title: 'International Womens Day',
    },
  ];

  return (
    <div className="events-section">
      <h1>OUR EVENTS</h1>
      <div className="events-container">
        {events.map((event, index) => (
          <div key={index} className="event-card">
            <img src={event.image} alt={event.title} className="event-image" />
            <div className="event-info">
              <h3 className="event-title">{event.title}</h3>
              <p className="event-description">{event.description}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="view-all-btn">VIEW ALL</button>
    </div>
  );
};


export default EventsSection;
