import React from 'react';
import './IEEEBranchRoles.css';

const rolesData = [
  {
    role: 'Chair IEEE Student Branch',
    current: { name: 'Jessica Baranwal', branch: 'IEEE Student Branch', year: '29.April.2023 - Present' },
    past: [
      { name: 'Sonam Negi', branch: 'IEEE Student Branch', year: '21.03.2021 - 30.03.2022' },
      { name: 'Khushboo Gairola', branch: 'IEEE Student Branch', year: '20.07.2018 - 21.03.2021' },
      { name: 'Srishti Aggarwal', branch: 'IEEE Student Branch', year: '01.07.2017 - 20.07.18' },
      { name: 'Minakshi Kotnala', branch: 'IEEE Student Branch', year: '20.06.2016 - 01.07.2017' }
    ]
  },
  {
    role: 'WIE Chair IEEE',
    current: { name: 'Bhavika Bhatt', branch: 'IEEE Student Branch', year: '04.10.2023 - Present' },
    past: [
      { name: 'Mansi Raturi', branch: 'IEEE Student Branch', year: '26.04.2023 - 04.10.2023' }
    ]
  },
  {
    role: 'Treasurer',
    current: { name: 'Nikita Pal', branch: 'IEEE Student Branch', year: '29.04.2023 - 04.10.2023' },
    past: [] // No past members for Treasurer
  },
  {
    role: 'WIT Advisor, WIE Affinity Group',
    current: { name: 'KC Mishra', branch: 'IEEE Student Branch', year: '04.10.2023 - Present' },
    past: [
      { name: 'Dr. Alaknanda Ashok', branch: 'IEEE Student Branch', year: '17.10.2016 - 04.10.2023' }
    ]
  },
  {
    role: 'Branch Counselor, IEEE Student Branch',
    current: { name: 'KC Mishra', branch: 'IEEE Student Branch', year: '2016 - Present' }
  }
];

const IEEEBranchRoles = () => {
  return (
    <div className="ieee-roles">
      <h2>IEEE Branch Leadership</h2>
      <table>
        <thead>
          <tr>
            <th>Role</th>
            <th>Current Member</th>
            <th>Past Members</th>
          </tr>
        </thead>
        <tbody>
          {rolesData.map((roleData, index) => (
            <tr key={index}>
              <td>{roleData.role}</td>
              <td>
                {roleData.current.name} <br />
                <span>{roleData.current.branch}, {roleData.current.year}</span>
              </td>
              <td>
                {roleData.past && roleData.past.length > 0 ? (
                  roleData.past.map((pastMember, idx) => (
                    <div key={idx}>
                      {pastMember.name} <br />
                      <span>{pastMember.branch}, {pastMember.year}</span>
                    </div>
                  ))
                ) : (
                  <span>No past members</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IEEEBranchRoles;
